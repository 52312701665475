// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-single-article-js": () => import("/home/astraea/frontline.astraeafoundation.org/src/components/singleArticle.js" /* webpackChunkName: "component---src-components-single-article-js" */),
  "component---src-components-article-gallery-js": () => import("/home/astraea/frontline.astraeafoundation.org/src/components/articleGallery.js" /* webpackChunkName: "component---src-components-article-gallery-js" */),
  "component---src-components-multiple-article-js": () => import("/home/astraea/frontline.astraeafoundation.org/src/components/multipleArticle.js" /* webpackChunkName: "component---src-components-multiple-article-js" */),
  "component---src-pages-404-js": () => import("/home/astraea/frontline.astraeafoundation.org/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/home/astraea/frontline.astraeafoundation.org/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-timeline-old-js": () => import("/home/astraea/frontline.astraeafoundation.org/src/pages/timeline-old.js" /* webpackChunkName: "component---src-pages-timeline-old-js" */)
}

