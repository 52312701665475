module.exports = [{
      plugin: require('/home/astraea/frontline.astraeafoundation.org/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#002630","theme_color":"#002630","display":"minimal-ui","icon":"src/images/astraea-icon.png"},
    },{
      plugin: require('/home/astraea/frontline.astraeafoundation.org/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/astraea/frontline.astraeafoundation.org/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
